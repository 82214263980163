import { createInertiaApp } from "@inertiajs/react";
import { IntlProvider } from "react-intl";
import { createRoot } from "react-dom/client";
import messagesEn from "../../lang/en.json";
import messagesIt from "../../lang/it.json";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Error from "@/components/common/errors/errorPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

Bugsnag.start({
    apiKey: "324f7da2bfcc88388d493350296fe770",
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const convertFile = (object) =>
    Object.fromEntries(
        Object.entries(object).map(([key, value]) => [
            key.replace(/\\'/g, "'"),
            value
                .replace(/:(\w+)/g, "{$1}")
                .replace(/\\'/g, "'")
                .replace("'{", "''{")
                .replace("}'", "}''"),
        ]),
    );
const messages = {
    en: convertFile(messagesEn),
    it: convertFile(messagesIt),
};

const UserLoggingProvider = ({ children, props }) => {
    const user = props?.initialPage?.props?.auth?.user?.id;
    if (user) {
        Bugsnag.setUser(
            user?.id,
            user?.email,
            `${user?.first_name} ${user?.last_name}`,
        );
    }
    return children;
};

createInertiaApp({
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx"),
        ),
    setup({ el, App, props }) {
        createRoot(el).render(
            <IntlProvider
                locale={props?.initialPage?.props?.locale || "en"}
                messages={messages[props?.initialPage?.props?.locale || "en"]}
            >
                <ErrorBoundary FallbackComponent={Error}>
                    <UserLoggingProvider props={props}>
                        <QueryClientProvider client={queryClient}>
                            <App {...props} />
                        </QueryClientProvider>
                    </UserLoggingProvider>
                </ErrorBoundary>
            </IntlProvider>,
        );
    },
});
